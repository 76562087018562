import {FC} from "react";
import {TabColumn} from "../../../tab-content";
import "./OperationalDetailsColumn.scss";
import {useOperationalEntity} from "../../context";
import {OperationalBusinessInformation} from "./OperationalBusinessInformation";
import {OperationalDetailsHeader} from "./OperationalDetailsHeader";
import {EnterpriseEntitlements} from "./EnterpriseEntitlements";
import {DealerStatusBar, Sources} from "../../overview";
import {OperationalAddress} from "./OperationalAddress";
import {OrganizationInformation} from "@common-core/coat-operational-hierarchy-appsync-model";
import {OperationalOems} from "./OperationalOems";

export const OperationalDetailsColumn: FC = () => {
  const {
    entity: {
      id,
      organizationInformation = {} as OrganizationInformation,
      oems,
      statuses = [],
      sources,
      isComplex
    }
  } = useOperationalEntity();

  const {address, telecomNumbers} = organizationInformation;

  return (
    <TabColumn name={"details-column"} header={<OperationalDetailsHeader />}>
      <DealerStatusBar id={id} statuses={statuses} />
      <OperationalBusinessInformation />
      <Sources id={id} sources={sources} isComplex={isComplex} />
      <OperationalAddress
        id={id}
        streetAddressLine1={address?.streetAddressLine1}
        streetAddressLine2={address?.streetAddressLine2}
        city={address?.city}
        state={address?.state}
        country={address?.country}
        zip={address?.zip}
        telecomNumbers={telecomNumbers ? telecomNumbers : []}
        sources={sources}
      />
      <OperationalOems id={id} oems={oems} sources={sources} />
      <EnterpriseEntitlements />
    </TabColumn>
  );
};
